<template>
  <div>
    <a-card class="card" title="基本信息" :bordered="false">
      <div slot="extra">
        <span style="margin-right: 20px">业务编号：{{ form.serial_num }}</span>
        <a-button><router-link :to="{ name: 'CumsInList' }">取消</router-link></a-button>
        <a-divider type="vertical" />
        <a-button :disabled="disabled ? true : false" type="primary" @click="handleSubmit" v-preventReClick>保存</a-button>
      </div>
      <a-form-model layout="vertical" :model="form" :rules="rules" ref="form">
        <a-row :gutter="20">
          <a-col :span="6">
            <a-form-model-item label="委托客户" prop="customer_id" :autoLink="false">
              <!-- <a-select
                showSearch
                label-in-value
                allowClear
                placeholder="请选择委托客户"
                style="width: 100%"
                :value="customerInfo"
                :filter-option="false"
                :not-found-content="fetching ? undefined : null"
                @search="getDepartmentCustomer"
                @change="changeCustomerInfo"
              >
                <template v-if="fetching" #notFoundContent>
                  <a-spin size="small" />
                </template>
                <a-select-option v-for="op in customerOps" :key="op.value">{{ op.name }}</a-select-option>
              </a-select> -->
              <a-input disabled v-model="form.agency_name" style="width:100%" />
            </a-form-model-item>
          </a-col>
          <a-col :span="6">
            <a-form-model-item label="操作时间">
              <a-date-picker v-model="form.operate_date" class="full-width"></a-date-picker>
            </a-form-model-item>
          </a-col>
          <a-col :span="6">
            <a-form-model-item label="操作人员">
              <a-select
                showSearch
                :filter-option="filterOption"
                allowClear
                placeholder="请选择操作人员"
                style="width: 100%"
                ref="operator_name"
                v-model="form.operate_person"
              >
                <a-select-option v-for="op in operatorOps" :key="op.value">{{ op.name }}</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="6">
            <a-form-model-item label="理货员">
              <a-select
                showSearch
                :filter-option="filterOption"
                allowClear
                placeholder="请选择理货员"
                style="width: 100%"
                ref="arrange_man"
                v-model="form.arrange_man_id"
              >
                <a-select-option v-for="op in operatorOps" :key="op.value">{{ op.name }}</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="6">
            <a-form-model-item label="入库总立方">
              <a-input-number v-model="form.in_cube" style="width:100%" />
            </a-form-model-item>
          </a-col>
          <a-col :span="6">
            <a-form-model-item label="入库日期" prop="box_time">
              <a-date-picker v-model="form.store_date" class="full-width" disabled></a-date-picker>
            </a-form-model-item>
          </a-col>
          <!-- <a-col :span="6">
            <a-form-model-item label="业务类型" v-if="!form.is_temp_drop_box">
              <a-select
                showSearch
                allowClear
                placeholder="请选择业务类型"
                style="width: 100%"
                v-model="form.business_type_id"
              >
                <a-select-option v-for="op in bizTypeOps" :key="op.value">{{ op.name }}</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col> -->
          <!-- <a-col :span="6">
            <a-form-model-item label="客户联系人">
              <a-select
                showSearch
                :filter-option="filterOption"
                allowClear
                placeholder="请选择客户联系人"
                style="width: 100%"
                ref="customer_contact"
                v-model="form.customer_contact_id"
              >
                <a-select-option v-for="op in customerContactOps" :key="op.value">{{ op.name }}</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="6">
            <a-form-model-item label="联系电话">
              <a-input v-model="form.contact_number" />
            </a-form-model-item>
          </a-col> -->
        </a-row>
        <!-- <a-row :gutter="20">
          <a-col :span="6" v-show="false">
            <a-form-model-item label="业务类型">
              <a-checkbox v-model="form.is_temp_drop_box">暂落箱</a-checkbox>
            </a-form-model-item>
          </a-col>
          <template v-if="form.is_temp_drop_box">
            <a-col :span="6">
              <a-form-model-item label="进场时间">
                <a-date-picker v-model="form.enter_time" class="full-width"></a-date-picker>
              </a-form-model-item>
            </a-col>
          </template>
        </a-row> -->
        <!-- <a-row :gutter="20">
          <a-col :md="24">
            <a-form-model-item label="箱型箱号箱量">
              <template v-for="(box, index) in form.box_items">
                <a-input-group
                  compact
                  :key="index"
                  :class="['field-item', 'box-item', form.is_temp_drop_box ? 'box-append-item' : '']"
                >
                  <a-select showSearch placeholder="箱型" style="width: 90px" v-model="box.box_id">
                    <a-select-option v-for="op in boxOps" :key="op.value">{{ op.name }}</a-select-option>
                  </a-select>
                  <a-input v-model="box.box_num" style="width: 120px" placeholder="箱号" />
                  <a-date-picker
                    v-model="box.exit_time"
                    style="width: 130px"
                    placeholder="出厂日期"
                    v-show="form.is_temp_drop_box"
                  ></a-date-picker>
                  <a-icon
                    type="close-circle"
                    theme="filled"
                    class="field-item_del"
                    v-if="index > 0"
                    @click="delItem('box_items', index)"
                  />
                  <a-button
                    type="primary"
                    v-if="index === form.box_items.length - 1"
                    style="width: 60px;margin-left: 10px"
                    @click="createItem('box_items')"
                  >更多</a-button
                  >
                </a-input-group>
              </template>
            </a-form-model-item>
          </a-col>
        </a-row> -->
        <a-row :gutter="20">
          <a-col :span="12">
            <a-form-model-item label="备注">
              <a-textarea disabled v-model="form.remark"></a-textarea>
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item label="反馈备注">
              <a-textarea v-model="form.feedback_remark"></a-textarea>
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-form-model>
    </a-card>
    <a-card class="card" title="其它信息" :bordered="false" >
      <edit-table
        scroll
        :operate="{ add: false, del: false }"
        :columns="cumsGoodsColumns"
        :sourceData="cumsGoodsData"
        @getTableDate="getCumsGoodsData"
      ></edit-table>
    </a-card>
  </div>
</template>

<script>
import { getCommonOptions } from '@/api/common'
import {
  getWmsDataOption,
  updateWmsInBusinessInfo,
  getWmsBusinsessDetail
} from '@/api/wms'
import { EditTable } from '@/components'
import { getFirstLetter } from '@/utils/util'
import moment from 'moment'
import preventReClick from '@/utils/preventReClick'
// import debounce from 'lodash/debounce';
export default {
  components: {
    EditTable,
    preventReClick
  },
  data() {
    // this.lastFetchId = 0;
    // this.getDepartmentCustomer = debounce(this.getDepartmentCustomer, 800);
    return {
      form: {
      },
      /** 200812去掉字段：车型、仓库、送货单位、客户编号、送货人、车牌号、车型、内部编号 */
      sourceForm: {
        id: null,
        serial_num: '',
        agency_name: '',
        operate_date: moment(),
        operate_person: null,
        arrange_man_id: null,
        in_cube: undefined,
        remark: '',
        feedback_remark: ''
      },
      disabled: false,
      // bizTypeOps: [],
      // customerOps: [],
      // customerInfo: undefined,
      // fetching: false,
      // customerContactOps: [],
      // boxOps: [],
      operatorOps: [], // 铲车工
      cumsGoodsData: [],
      rules: {
        // customer_id: [{ required: true, message: '请选择委托客户', trigger: 'blur' }]
      },
      cumsGoodsColumns: [
        {
          title: '#',
          width: 50,
          align: 'center',
          customRender: (text, record, index) => {
            return index + 1
          }
        },
        {
          title: '商品名称',
          dataIndex: 'good_name',
          width: 150,
          extra: {
            disabled: record => {
              return true
            }
          }
        },
        {
          title: '商品规格型号',
          dataIndex: 'good_spec',
          width: 150,
          extra: {
            disabled: record => {
              return true
            }
          }
        },
        {
          title: '商品料号',
          dataIndex: 'good_material',
          width: 100,
          extra: {
            disabled: record => {
              return true
            }
          }
        },
        {
          title: '灌装日期',
          dataIndex: 'filling_date',
          width: 160,
          extra: {
            type: 'date',
            disabled: record => {
              return true
            }
          }
        },
        {
          title: '货物单位',
          dataIndex: 'unit',
          width: 100,
          extra: {
            disabled: record => {
              return true
            }
          }
        },
        {
          title: '货物数量',
          dataIndex: 'num',
          width: 100,
          extra: {
            type: 'number',
            disabled: record => {
              return true
            }
          }
        },
        {
          title: '申报计量单位',
          dataIndex: 'upload_unit',
          width: 130,
          extra: {
            disabled: record => {
              return true
            }
          }
        },
        {
          title: '申报数',
          dataIndex: 'upload_num',
          width: 100,
          extra: {
            disabled: record => {
              return true
            }
          }
        },
        {
          title: '法定计量单位',
          dataIndex: 'legal_unit',
          width: 130,
          extra: {
            disabled: record => {
              return true
            }
          }
        },
        {
          title: '法定数量',
          dataIndex: 'legal_num',
          width: 100,
          extra: {
            disabled: record => {
              return true
            }
          }
        },
        {
          title: '毛重',
          dataIndex: 'gloss_weight',
          width: 100,
          extra: {
            disabled: record => {
              return true
            }
          }
        },
        {
          title: '净重',
          dataIndex: 'weight',
          width: 100,
          extra: {
            disabled: record => {
              return true
            }
          }
        },
        {
          title: '库位',
          dataIndex: 'stock_place',
          width: 100
        }
      ]
    }
  },
  watch: {
    $route: function(newRoute) {
      if (!this.queryParam && newRoute.name === 'CumsInCUR') {
        // 编辑页路由监控
        this.handleFormReset()
        this.getEditData(newRoute.params.id)
      }
    }
  },
  mounted() {
    this.getEditData(this.$route.params.id)
  },
  created() {
    this.handleFormReset()
    this.getFormBasicData()
  },
  methods: {
    getWmsDataOption,
    getCommonOptions,
    moment,
    handleFormReset() {
      for (const key in this.sourceForm) {
        this.$set(this.form, key, this.sourceForm[key])
      }
      // this.customerInfo = undefined
      this.cumsGoodsData = []
      this.$nextTick(_ => {
        this.$refs.form.clearValidate()
      })
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
        getFirstLetter(option.componentOptions.children[0].text)
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      )
    },
    getEditData(id) {
      getWmsBusinsessDetail('in', { id: id }).then(v => {
        const record = v.record
        record['warehouse_operate_date'] = record['warehouse_operate_date'] ? moment(record['warehouse_operate_date'], 'YYYY/MM/DD') : null
        this.form = {
          ...record,
          operate_date: record.warehouse_operate_date,
          operate_person: record.warehouse_operate_person ? record.warehouse_operate_person : null,
          arrange_man_id: record.warehouse_arrange_person ? record.warehouse_arrange_person : null,
          in_cube: record.warehouse_in_cube,
          feedback_remark: record.feed_back,
          store_date: record.store_date
        }
        this.cumsGoodsData = v.cums_goods_list
      })
    },
    // getDepartmentCustomer(value) {
    //   this.lastFetchId += 1;
    //   const fetchId = this.lastFetchId;
    //   this.customerOps = [];
    //   this.fetching = true;
    //   getCustomer({
    //     department: 7,
    //     customerName: value
    //   }).then(res => {
    //     if (fetchId !== this.lastFetchId) {
    //       return;
    //     }
    //     this.customerOps = res
    //     this.fetching = false;
    //   })
    // },
    // changeCustomerInfo(value) {
    //   this.customerInfo = value
    //   this.form['customer_id'] = value.key
    //   this.form['customer'] = value.label
    //   this.customerOps = []
    //   this.fetching = false
    // },
    getFormBasicData() {
      // getCommonOptions('box_info').then(v => {
      //   this.boxOps = v
      // })
      getWmsDataOption('operator').then(v => {
        this.operatorOps = v
      })
      // getWmsMetaOption('wms_business_type').then(v => {
      //   this.bizTypeOps = v
      // })
    },
    // createItem(field) {
    //   // 增加更多箱型
    //   if (field === 'box_items') {
    //     this.form.box_items.push({ box_id: null, box_num: null, exit_time: null })
    //   } else {
    //     this.form[field].push('')
    //   }
    // },
    // delItem(field, index) {
    //   this.form[field].splice(index, 1)
    // },
    // handleCustomerChange(a, b) {
    //   this.form.customer_contact_id = null
    // },
    getCumsGoodsData(data) {
      this.cumsGoodsData = data
    },
    handleSubmit() {
      this.$refs.form.validate(valid => {
        if (valid) {
          updateWmsInBusinessInfo({
              ...this.form,
              operate_date: this.form.operate_date ? moment(this.form.operate_date).valueOf() : null,
              cums_good_list: this.cumsGoodsData
            }).then(_ => {
            this.$notification['success']({
              message: '提示',
              description: '编辑成功'
            })
            this.$router.push({
              name: 'CumsInList'
            })
          })
        } else {
          return false
        }
      })
    }
  }
}
</script>

<style scoped>
.sub-model {
  width: 32%;
  margin-right: 1%;
}
.sub-model-title {
  position: relative;
  padding: 0 11px;
  line-height: 30px;
  color: rgba(0, 0, 0, 0.65);
  font-weight: normal;
  font-size: 14px;
  text-align: center;
  background-color: #fafafa;
  border: 1px solid #d9d9d9;
  border-right: 0;
}
.sub-model .ant-input-number {
  width: calc(100% - 55px);
}

.box-append-item {
  width: 340px;
}
.box-append-item:last-child {
  width: 540px;
}
.box-append-item .field-item_del {
  left: 330px;
}
</style>
